import React from 'react';
import "video-react/dist/video-react.css";
import ReactPlayer from 'react-player';
import { FaLink, FaPlayCircle, FaAsymmetrik } from 'react-icons/fa';
import { IoIosHome } from 'react-icons/io';
import {
  Alert,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  Form,
  Button,
} from 'reactstrap';
import VdsLoader from './VdsLoader';
import Videos from './Videos';
import Detail from './Detail';
import VdsParticle from './VdsParticle';
import 'bootstrap/dist/css/bootstrap.min.css';
import Playlist from './Playlist';
import axios from 'axios';



class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {showLoader: false, showResult: false, urlValue: '', showError: false, result: {}, error:{}, playerActive: false, playerSource: '', thumbnail: '', resultSuccess:true};
    this.updateUrlValue = this.updateUrlValue.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.showPlayer = this.showPlayer.bind(this);
  }
/**
* Update the URL value in Global state.
*/
  updateUrlValue(evt) {
    this.setState({
        urlValue: evt.target.value
      });
  }

/**
* Shows the Player, loads video and play on click of thumbnail
*/
  showPlayer(){
    var player = this.player;
    var src = "";
    var formatLengths = 0;

    if(this.state.result.data.extractor == 'youtube'){
      formatLengths = this.state.result.data.formats.length;
      src = this.state.result.data.formats[formatLengths - 1].url;
    }

    if(this.state.result.data.url){
      src = this.state.result.data.url;
    }

    this.setState({
        playerActive:true,
        playerSource:src
      });

    //player.load();
    //setTimeout(function(player){ player.play();}, 500, player);

  }

  onSearchClick(props) {

    this.setState({showLoader: true, showResult: false, showError: false, playerActive: false});

    axios.get('https://el6c66a9h9.execute-api.us-east-2.amazonaws.com/development?url='+this.state.urlValue)
      .then((res) => {
        if(res.data.thumbnail){
          var thumbnail = res.data.thumbnail;
        } else {
          var thumbnail = process.env.PUBLIC_URL + '/placeholder.jpg';
        }
        this.setState({showLoader: false, showResult: true, showError: false, result: res, thumbnail: thumbnail});

        if(res.data.error){
          this.setState({showLoader: false, showResult: true, showError: true, error: res.data.error});
        }
      }, (error) => {
        this.setState({showLoader: false, showError: true, error: 'Unable to retrieve video'});
      }).catch(err => {

  });
  }

  convertSecondstoTime(given_seconds) {
    var dateObj = new Date(given_seconds * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString = hours.toString().padStart(2, '0')
        + ':' + minutes.toString().padStart(2, '0')
        + ':' + seconds.toString().padStart(2, '0');

      return timeString;
  }



render() {

    return (
    <div id="main">
      <div className="download-sec">
        <VdsParticle/>
      <div className="download-sec-main">
      <Container className="vds-form">
        <Row>
          <Col xs="12" sm="12" className="vds-banner-gif">
          <img src="Placeholder.gif" />
          </Col>
          <Col xs="12" sm="12" md="12" className="download-sec-text">
            <p>Fastest & Easiest way to Download Videos from Any Websites.</p>
            <p><b>Watch Your Favorite Online Videos Offline.</b></p>
          </Col>
        </Row>
      </Container>
      <Container className="vds-form-wrapper">
        <Row>
        <Col xs="12" sm="12" md="12" className="mt-2 youtube-downloadfrm">
          <Form className="vds-form">
          <InputGroup className="vds-form-group">
              <Input placeholder="Enter your URL here..." name="url" id="vds-url" value={this.state.urlValue} onKeyPress={(event) => {

                if (event.key === "Enter" || event.keyCode == 13) {
                  event.preventDefault();
                  this.onSearchClick();
                }
  }} onChange={this.updateUrlValue} />
  <FaLink className="email-icon" />
              <InputGroupAddon addonType="append"><Button onClick={() => { this.onSearchClick() }}> Download Now </Button></InputGroupAddon>
          </InputGroup>
          </Form>
          </Col>
        </Row>
      </Container>
      </div>
      { this.state.showLoader ? <Container className="vds-loader">
      <Row>
        <Col xs="12" sm="12" md="12" className="mt-3">
          <VdsLoader/>
        </Col>
      </Row>
      </Container> : null }

      { this.state.showError ? <Container className="vds-error">
      <Row>
        <Col xs="12" sm="12" md="12" className="mt-3">
              <Alert color="danger">
                { this.state.error}
              </Alert>
        </Col>
      </Row>
      </Container> : null }
      </div>

      { this.state.showResult ?
      <React.Fragment>
      <div className="vds-go-home-bar">
        <Button color="secondary" onClick={() => {this.setState({showResult:false})}} ><IoIosHome /> Go Back to Home</Button>
      </div>
      <Container className="vds-results">
      {!this.state.result.data.entries ?
        <Row>
        <Col xs="12" sm="12" md="12" className="mt-md-5 mt-4">
        <Alert color="info">
          Video Extracted Successfully
        </Alert>
        </Col>

          <Col xs="12" sm="6" md="6" className="mt-md-5 mt-4">
              {( this.state.thumbnail && this.state.result.data.title) ? <div>
              <div className={(this.state.playerActive)? "d-none": "vds-thumbnail-block"} onClick={() => { this.showPlayer() }}><FaPlayCircle/><img src={this.state.thumbnail} className={(this.state.playerActive)? "d-none": "img-fluid"}  alt={this.state.result.data.title}  /></div>
              <ReactPlayer url={this.state.playerSource} playing controls width="100%" className={(this.state.playerActive)? "": "d-none"} />
               </div>: null }
          </Col>
          <Col xs="12" sm="6" md="6" className="mt-md-5 mt-4 youtube-dataText">
              {this.state.result.data.title ? <h3>{this.state.result.data.title}</h3> : null }
              {this.state.result.data.duration ? <p><strong>Duration:</strong> {this.convertSecondstoTime(this.state.result.data.duration)} </p> : null }
              {this.state.result.data.url ? <p> <a href={this.state.result.data.url} className="btn btn-primary" target="blank" >Download Now</a> </p>: null }
              { (this.state.result.data.extractor == 'youtube') ? <p> <a href={this.state.result.data.formats[this.state.result.data.formats.length -1].url} className="btn btn-primary" target="blank" >Download Now</a> </p>: null }
          </Col>
        </Row> : null }
        <Row>
          {this.state.result.data.entries ? <Playlist entries={this.state.result.data.entries} /> : null }
        </Row>
        <Row>
          <div className="mt-md-5 mt-2 data-details">{ (this.state.result.data.formats && this.state.result.data.formats.length > 1 ) ? <Detail data={this.state.result.data.formats} /> : null }
        </div></Row>
      </Container>
       </React.Fragment>: <Videos/> }

    </div>
    );
}
}
export default Main;

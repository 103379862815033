import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoIosArrowDown,IoIosDownload, IoMdPlay, IoIosClose } from 'react-icons/io';
import {Button} from 'reactstrap';

function VdsSlideItem(props) {
  return (
      <div className="item-slide-img">
        <img src={props.detail.VideoThumbnail != "#" ? props.detail.VideoThumbnail : process.env.PUBLIC_URL + '/placeholder.jpg'} />
        <div className="item-slide-text">
          <Button color="secondary" onClick={() => {props.handlePlayPlayer(props.detail)} } ><IoMdPlay /> Play</Button>
          <Button color="secondary" onClick={() => {props.handleDownloadPlayer(props.detail)} } ><IoIosDownload /> Download</Button>
          <p>{props.detail.VideoTitle}</p>
          <div className="item-arrow">
          <IoIosArrowDown />
        </div>
       </div>
      </div>
  );
}

export default VdsSlideItem;

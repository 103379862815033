import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPlayer from 'react-player'
import { FaCloudDownloadAlt, FaPlayCircle } from 'react-icons/fa';
import Detail from './Detail';
import {
  Container,
  Col,
  Row,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
  } from 'reactstrap';

  class Playlist extends React.Component {

    constructor(props) {
      super(props);
      this.state = {entries:props.entries, modal: false,
            fade: true, formats: false, playerActive: false, playerSource: '', playerItem: ''};

      this.buttonLabel = props;
      this.className = props;
      this.showVdsModal = this.showVdsModal.bind(this);
    }

    showVdsModal(evt) {
        let VdsValue;
      if(evt.target.value){
         VdsValue = JSON.parse(evt.target.value);
      } else {
         VdsValue = false
      }

      this.setState({
          modal: !this.state.modal,
          fade: !this.state.fade,
          formats: VdsValue

      });
    }


  /**
  * Shows the Player, loads video and play on click of thumbnail
  */
  showPlayer(item){
      var player = this.player;
      var src = "";
      console.log(item);

      if(item.extractor == 'youtube'){
          src = item.requested_formats[0].url;
      }

      if(item.url){
          src = item.url;
      }




      this.setState({
            playerActive:true,
            playerSource:src
      });

      //player.load();
      //setTimeout(function(player){ player.play();}, 500, player);

    }


    render() {
      return (
        <React.Fragment>
        <Col xs="12" sm="12" md="12" className="mt-md-5 mt-4">
          <Alert color="info">
            Video Extracted Successfully
          </Alert>
        </Col>
        <Col xs="12" sm="12" md="12" id="playlist" className="my-2 youtube-dataText">
          <Container>
          { this.state.entries.map(item =>
            <Row className="my-md-5 my-2 ">
              <Col xs="12" sm="12" md="6">
                  <div>
                  <div className={(this.state.playerActive)? "d-none": "vds-thumbnail-block"}  onClick={() => { this.showPlayer(item) }}><FaPlayCircle/><img src={item.thumbnail? item.thumbnail : process.env.PUBLIC_URL + '/placeholder.jpg'} className={(this.state.playerActive)? "d-none": "img-fluid"}  alt={item.title} /></div>
                  <ReactPlayer url={this.state.playerSource} playing={this.state.playerActive} width="100%" controls  className={(this.state.playerActive)? "": "d-none"} />
         </div>
              </Col>
              <Col className="my-md-0 my-2" xs="12" sm="12" md="6">
                  {item.title ? <h3>{item.title}</h3> : null }
                  {item.duration ? <p><strong>Duration:</strong> {item.duration} </p> : null }
                  {item.url ? <p> <a href={item.url} className="btn btn-primary" target="blank" >Download Now</a> </p>: null }
                  { (item.extractor == 'youtube') ? <p> <a href={item.formats[item.formats.length - 1].url} className="btn btn-primary" target="blank" >Download Now</a> </p>: null }
                  { (item.formats) ? <Button outline color="primary" value={JSON.stringify(item.formats, null, 2)} onClick={this.showVdsModal}>Other Formats</Button> : null}
              </Col>
            </Row>)}
          </Container>

          <div>

             <Modal isOpen={this.state.modal} toggle={this.showVdsModal}
                    fade={this.state.fade}
                    className={this.props.className} size="lg">
                 <ModalHeader toggle={this.showVdsModal}>Other Formats</ModalHeader>
                 <ModalBody className="data-details">
                  <Detail data={this.state.formats} />

                 </ModalBody>
                 <ModalFooter>
                     <Button color="secondary" onClick={this.showVdsModal}>Back</Button>
                 </ModalFooter>
             </Modal>
           </div>

        </Col>
        </React.Fragment>
      );
    }
  }

export default Playlist;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Col,
  Row,
  Table,
  Button
  } from 'reactstrap';

  class Detail extends React.Component {
    constructor(props) {
      super(props);
      this.state = {formats:props.data};
    }

    bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }
    render() {
      return (


        <Col xs="12" sm="12" md="12" id="details" className="my-2">

          <h2 className="my-2 mb-4">Other Formats</h2>
          <Table striped>
            <thead>
              <tr>
                <th data-label="Format">Format</th>
                <th data-label="Extension">Extension</th>
                <th data-label="Size">Size</th>
                <th data-label="Download URL">Download URL</th>
              </tr>
            </thead>
            <tbody>
            { this.state.formats.map(format => <tr>
              <td data-label="Format"> {format.format_note}</td>
              <td data-label="Extension"> {format.ext}</td>
              <td data-label="Size"> {format.filesize ? this.bytesToSize(format.filesize): null }</td>
              <td data-label="Download URL"> <a href={format.url} className="btn btn-outline-primary" target="blank" >Download Now</a></td>
            </tr>)}

            </tbody>
          </Table>
        </Col>

      );
    }
  }

export default Detail;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

  class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <div id="footer" className="abc">
        <div className="downloadVideo-sec">
        <div className="container">
        <h2>#Want to Download Videos?</h2>
        <h3 className="catch-head">Catch Video From Any Site. Free Online Video Downloader</h3>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when  scrambled an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, scrambled.</p>
        <p>But also the leap into electronic. Typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem
        scrambled Ipsum passages, and more recently with desktop publishing software like Aldus Pag Maker including versions of Lorem, scrambled.</p>
        </div>
        </div>

        <div className="support-logo">
          <h3 className="catch-head">We Supports 1000+ Online Video Websites.</h3>
          <ul>
            <li>
              <span>
                <img src="youtube.png" /></span>
            </li>
            <li>
              <span>
                <img src="vimeo.png" /></span>
            </li>
            <li>
              <span>
                <img src="facebook.png" /></span>
            </li>
            <li>
              <span>
                <img src="instagram.png" /></span>
            </li>
            <li>
              <span>
                <img src="soundcloud.png" /></span>
            </li>
            <li>
              <span>
                <img src="twitter.png" /></span>
            </li>
            <li>
              <span>
                <img src="tumbler.png" /></span>
            </li>
            <li>
              <span>
                <img src="vk.png" /></span>
            </li>
            <li>
              <span>
                <img src="twitch.png" /></span>
            </li>
            <li>
              <span>
                <img src="dailymotion.png" /></span>
            </li>
          </ul>
        </div>
        <div className="copyrigh">
          <p>Copyright © 2019 VideoDownloadService. All Rights Reserved.</p>
        </div>

        </div>
      );
    }
  }

export default Footer;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Spinner, Alert} from 'reactstrap';

function VdsLoader() {
  return (
    <div className="">
      <div className="vds-loader-message">
        <Alert color="info">
          Extracting Video...
        </Alert>
      </div>
    <div className="spinnerSec">
      <Spinner type="grow" className="spinnerbg" color="primary" />
      <Spinner type="grow" className="spinnerbg" color="info" />
      <Spinner type="grow" className="spinnerbg" color="primary" />
      <Spinner type="grow" className="spinnerbg" color="info" />
      <Spinner type="grow" className="spinnerbg" color="primary" />
      <Spinner type="grow" className="spinnerbg" color="info" />
      <Spinner type="grow" className="spinnerbg" color="primary" />
      <Spinner type="grow" className="spinnerbg" color="info" />
    </div>
  </div>
  );
}

export default VdsLoader;

import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VdsSlideItem from "./VdsSlideItem";
import VdsLoader from "./VdsLoader";
import Slider from "react-slick";
import ReactPlayer from 'react-player';
import axios from 'axios';
import { IoIosArrowDown, IoIosDownload, IoMdPlay, IoIosClose } from 'react-icons/io';
import scrollToComponent from 'react-scroll-to-component';
import {Button} from 'reactstrap';

class Videos extends React.Component {
  constructor(props){
      super(props);
      this.state = {showSlidePlayer: false, slidePlayerThumbnail : false, slidePlayerSrc : false, currentSlider: false, currentSliderVideo: false, onlineVideos:false, currentSliderVideoPlayerSource: false, currentSliderVideoPlayerLoader: false, currentSliderPlayerActive: false};

  }


  componentDidMount(){
    axios.get('https://p5wmbswgs6.execute-api.us-east-2.amazonaws.com/development')
      .then((res) => {
        this.setState({onlineVideos: res.data});
      })
  }


onPlayClick(videoUrl) {
  this.setState({currentSliderVideoPlayerLoader: true, currentSliderPlayerActive: true});

  axios.get('https://el6c66a9h9.execute-api.us-east-2.amazonaws.com/development?url='+videoUrl)
    .then((res) => {
      var url;
      this.setState({currentSliderVideoPlayerLoader: false});
      if(res.data.hasOwnProperty('url')){
        url = res.data.url;
      }

      if(res.data.hasOwnProperty('formats')){
        var length = res.data.formats.length;
        var data = res.data.formats[length - 1];
        if(data.hasOwnProperty('url')){
            url = data.url;
        }
      }

      if(res.data.hasOwnProperty('entries')){
        var data = res.data.entries[0];
        if(data.hasOwnProperty('url')){
            url = data.url;
        }

        if(data.hasOwnProperty('formats')){
            var subLength = data.formats.length;
            var subData = data.formats[subLength - 1];
            if(data.hasOwnProperty('url')){
                url = subData.url;
            }
        }
      }

      this.setState({currentSliderVideoPlayerSource: url});

      if(res.data.error){

      }
    }, (error) => {

    }).catch(err => {

});
}

downloadVideo(dataurl, filename) {
  var a = document.createElement("a");
  a.href = dataurl;
  a.target = "_blank";
  a.setAttribute("download", filename);
  a.click();
}

onDownloadClick(videoUrl) {
  this.setState({currentSliderVideoPlayerLoader: true, currentSliderPlayerActive: true});

  axios.get('https://el6c66a9h9.execute-api.us-east-2.amazonaws.com/development?url='+videoUrl)
    .then((res) => {
      var url;
      this.setState({currentSliderVideoPlayerLoader: false});
      if(res.data.hasOwnProperty('url')){
        url = res.data.url;
      }

      if(res.data.hasOwnProperty('requested_formats')){
        var length = res.data.formats.length;
        var data = res.data.formats[length - 1];
        if(data.hasOwnProperty('url')){
            url = data.url;
        }
      }

      if(res.data.hasOwnProperty('entries')){
        var data = res.data.entries[0];
        if(data.hasOwnProperty('url')){
            url = data.url;
        }

        if(data.hasOwnProperty('requested_formats')){
            var subLength = data.formats.length;
            var subData = data.formats[subLength - 1];
            if(data.hasOwnProperty('url')){
                url = subData.url;
            }
        }
      }

      this.downloadVideo(url, 'video.mp4');

      if(res.data.error){

      }
    }, (error) => {

    }).catch(err => {

});
}


handleOpenPlayer(e, currentSlider, currentSliderVideo){
  this.setState({showSlidePlayer: true, currentSlider: currentSlider, currentSliderVideo: currentSliderVideo, currentSliderVideoPlayerSource: false, currentSliderPlayerActive: false});
}

handleDownloadPlayer(item){
  this.setState({currentSliderPlayerActive: true});
  this.onDownloadClick(item.Id);
}

handlePlayPlayer(item){
  this.setState({currentSliderPlayerActive: true});
  this.onPlayClick(item.Id)
}


handleClosePlayer(e){
  this.setState({showSlidePlayer: false, currentSliderVideo : false, currentSliderVideoPlayerSource: false, currentSliderPlayerActive: false});
}

render() {
 const settings = {
    dots: false,
    prevArrow: false,
    nextArrow: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
     ]
    };




    return (


      <div className="videoSection">

        <div className="video-header">
        <ul>
          <li>
            <a onClick={() => scrollToComponent(this.vds_trending, { offset: -122, align: 'top', duration: 800, ease:'inCirc'})}>Trending Videos</a>
          </li>
          <li>
            <a onClick={() => scrollToComponent(this.vds_facebook, { offset: -122, align: 'top', duration: 800, ease:'inCirc'})}>Top Picks From Facebook</a>
          </li>
          <li>
            <a onClick={() => scrollToComponent(this.vds_twitter, { offset: -122, align: 'top', duration: 800, ease:'inCirc'})}>Twitter Videos</a>
          </li>
          <li>
            <a onClick={() => scrollToComponent(this.vds_instagram, { offset: -122, align: 'top', duration: 800, ease:'inCirc'})}>Instagram Videos</a>
          </li>
        </ul>
        </div>



           <div className="trending-video-sec">
            { this.state.onlineVideos ?
            <div className="trending-video" ref={(div) => { this.vds_trending = div; }}>
            <div className="trending-video-focus">
          	 <h3>Trending Videos</h3>


               <Slider {...settings}>
               { this.state.onlineVideos.trending.map((item, index) =>
                    <div key={index} className={(this.state.currentSliderVideo.Id == item.Id && this.state.currentSlider == 'trending') ? 'item-slide selected': 'item-slide' } onClick={(e)=> {this.handleOpenPlayer(e,'trending', item)}}> <VdsSlideItem  key={index} detail={item} handlePlayPlayer={this.handlePlayPlayer.bind(this)}  handleDownloadPlayer={this.handleDownloadPlayer.bind(this)} /></div>
               )}
                </Slider>





                { (this.state.showSlidePlayer && this.state.currentSlider == 'trending')? <div className={ this.state.currentSliderPlayerActive ? "videoplay-sec vds-videoplay-active" : "videoplay-sec"}>
                    <div className="sec-close" onClick={() => {this.handleClosePlayer()}}>
                        <IoIosClose />
                      </div>

                      <div className="playvideoImg">
                            <img src={this.state.currentSliderVideo.VideoThumbnail != "#" ? this.state.currentSliderVideo.VideoThumbnail : process.env.PUBLIC_URL + '/placeholder.jpg'} />
                      </div>
                      <div className={this.state.currentSliderVideoPlayerSource ? "item-slide-text vds-player-active" : "item-slide-text"}>
                        {this.state.currentSliderVideoPlayerSource?
                            <div className="vds-video-player-wrap"><ReactPlayer url={this.state.currentSliderVideoPlayerSource} playing controls width="100%" /></div>:
                            <div className="vds-video-text-wrap">
                            {this.state.currentSliderVideoPlayerLoader ?
                              <VdsLoader/> :
                              <div>
                                  <Button color="secondary" onClick={() => {this.onPlayClick(this.state.currentSliderVideo.Id)}} ><IoMdPlay /> Play</Button>
                                  <Button color="secondary" onClick={() => {this.onDownloadClick(this.state.currentSliderVideo.Id)}} ><IoIosDownload /> Download</Button>
                                   <h3>{this.state.currentSliderVideo.VideoTitle}</h3>
                                   <p>{this.state.currentSliderVideo.VideoDescription}</p>
                              </div>
                            }
                            </div>
                        }
                      </div>
                </div> : ''}
            </div>

            </div> : ''}

              { this.state.onlineVideos ?
              <div className="trending-video" ref={(div) => { this.vds_facebook = div; }}>
                <div className="trending-video-focus">
                 <h3>Top Picks From Facebook</h3>

                   <Slider {...settings}>
                     {this.state.onlineVideos.facebook.map((item, index) =>
                        <div key={index} className={(this.state.currentSliderVideo.Id == item.Id && this.state.currentSlider == 'facebook') ? 'item-slide selected': 'item-slide' } onClick={(e)=> {this.handleOpenPlayer(e, 'facebook', item)}}><VdsSlideItem onClick={()=> {this.handleOpenPlayer()}} key={index} detail={item} handlePlayPlayer={this.handlePlayPlayer.bind(this)} handleDownloadPlayer={this.handleDownloadPlayer.bind(this)} /></div>
                     ) }
                    </Slider>

                    { (this.state.showSlidePlayer && this.state.currentSlider == 'facebook' ) ? <div className={ this.state.currentSliderPlayerActive ? "videoplay-sec vds-videoplay-active" : "videoplay-sec"}>
                        <div className="sec-close" onClick={() => {this.handleClosePlayer()}}>
                            <IoIosClose />
                          </div>

                          <div className="playvideoImg">
                                <img src={this.state.currentSliderVideo.VideoThumbnail != "#" ? this.state.currentSliderVideo.VideoThumbnail : process.env.PUBLIC_URL + '/placeholder.jpg'} />
                          </div>
                          <div className={this.state.currentSliderVideoPlayerSource ? "item-slide-text vds-player-active" : "item-slide-text"}>
                          {this.state.currentSliderVideoPlayerSource?
                              <div className="vds-video-player-wrap"><ReactPlayer url={this.state.currentSliderVideoPlayerSource} playing controls width="100%" /></div>:
                              <div className="vds-video-text-wrap">
                              {this.state.currentSliderVideoPlayerLoader ?
                                <VdsLoader/> :
                                <div>
                                    <Button color="secondary" onClick={() => {this.onPlayClick(this.state.currentSliderVideo.Id)}} ><IoMdPlay /> Play</Button>
                                    <Button color="secondary" onClick={() => {this.onDownloadClick(this.state.currentSliderVideo.Id)}} ><IoIosDownload /> Download</Button>
                                     <h3>{this.state.currentSliderVideo.VideoTitle}</h3>
                                     <p>{this.state.currentSliderVideo.VideoDescription}</p>
                                </div>
                              }
                              </div>
                          }
                          </div>
                    </div> : ''}

                </div>
              </div> : ''}

              { this.state.onlineVideos ?
              <div className="trending-video" ref={(div) => { this.vds_twitter = div; }}>
                <div className="trending-video-focus">
                 <h3>Twitter Videos</h3>

                   <Slider {...settings}>
                       {this.state.onlineVideos.twitter.map((item, index) =>
                          <div key={index} className={(this.state.currentSliderVideo.Id == item.Id && this.state.currentSlider == 'twitter') ? 'item-slide selected': 'item-slide' } onClick={(e)=> {this.handleOpenPlayer(e, 'twitter', item)}}><VdsSlideItem onClick={()=> {this.handleOpenPlayer()}} key={index} detail={item} handlePlayPlayer={this.handlePlayPlayer.bind(this)} handleDownloadPlayer={this.handleDownloadPlayer.bind(this)} /></div>
                       ) }
                    </Slider>

                    { (this.state.showSlidePlayer && this.state.currentSlider == 'twitter' ) ? <div className={ this.state.currentSliderPlayerActive ? "videoplay-sec vds-videoplay-active" : "videoplay-sec"}>
                        <div className="sec-close" onClick={() => {this.handleClosePlayer()}}>
                            <IoIosClose />
                          </div>

                        <div className="playvideoImg">
                            <img src={this.state.currentSliderVideo.VideoThumbnail != "#" ? this.state.currentSliderVideo.VideoThumbnail : process.env.PUBLIC_URL + '/placeholder.jpg'} />
                        </div>
                        <div className={this.state.currentSliderVideoPlayerSource ? "item-slide-text vds-player-active" : "item-slide-text"}>
                          {this.state.currentSliderVideoPlayerSource?
                              <div className="vds-video-player-wrap"><ReactPlayer url={this.state.currentSliderVideoPlayerSource} playing controls width="100%" /></div>:
                              <div className="vds-video-text-wrap">
                              {this.state.currentSliderVideoPlayerLoader ?
                                <VdsLoader/> :
                                <div>
                                    <Button color="secondary" onClick={() => {this.onPlayClick(this.state.currentSliderVideo.Id)}} ><IoMdPlay /> Play</Button>
                                    <Button color="secondary" onClick={() => {this.onDownloadClick(this.state.currentSliderVideo.Id)}} ><IoIosDownload /> Download</Button>
                                     <h3>{this.state.currentSliderVideo.VideoTitle}</h3>
                                     <p>{this.state.currentSliderVideo.VideoDescription}</p>
                                </div>
                              }
                              </div>
                          }
                        </div>
                    </div> : ''}

                </div>
              </div>: ''}

              { this.state.onlineVideos ?
              <div className="trending-video" ref={(div) => { this.vds_instagram = div; }}>
                <div className="trending-video-focus">
                 <h3>Instagram Videos</h3>

                 <Slider {...settings}>

                   { this.state.onlineVideos.instagram.map((item, index) =>
                      <div key={index} className={(this.state.currentSliderVideo.Id == item.Id && this.state.currentSlider == 'instagram') ? 'item-slide selected': 'item-slide' } onClick={(e)=> {this.handleOpenPlayer(e,'instagram', item)}}><VdsSlideItem onClick={()=> {this.handleOpenPlayer()}} key={index} detail={item} handlePlayPlayer={this.handlePlayPlayer.bind(this)} handleDownloadPlayer={this.handleDownloadPlayer.bind(this)} /></div>
                   ) }
                  </Slider>

                  { (this.state.showSlidePlayer && this.state.currentSlider == 'instagram' ) ? <div className={ this.state.currentSliderPlayerActive ? "videoplay-sec vds-videoplay-active" : "videoplay-sec"}>
                      <div className="sec-close" onClick={() => {this.handleClosePlayer()}} >
                          <IoIosClose />
                        </div>

                        <div className="playvideoImg">
                            <img src={this.state.currentSliderVideo.VideoThumbnail != "#" ? this.state.currentSliderVideo.VideoThumbnail : process.env.PUBLIC_URL + '/placeholder.jpg'} />
                        </div>
                        <div className={this.state.currentSliderVideoPlayerSource ? "item-slide-text vds-player-active" : "item-slide-text"}>
                          {this.state.currentSliderVideoPlayerSource?
                              <div className="vds-video-player-wrap"><ReactPlayer url={this.state.currentSliderVideoPlayerSource} playing controls width="100%" /></div>:
                              <div className="vds-video-text-wrap">
                              {this.state.currentSliderVideoPlayerLoader ?
                                <VdsLoader/> :
                                <div>
                                    <Button color="secondary" onClick={() => {this.onPlayClick(this.state.currentSliderVideo.Id)}} ><IoMdPlay /> Play</Button>
                                    <Button color="secondary" onClick={() => {this.onDownloadClick(this.state.currentSliderVideo.Id)}} ><IoIosDownload /> Download</Button>
                                     <h3>{this.state.currentSliderVideo.VideoTitle}</h3>
                                     <p>{this.state.currentSliderVideo.VideoDescription}</p>
                                </div>
                              }
                              </div>
                          }
                        </div>
                  </div> : ''}

                </div>
              </div> : ''}


              { this.state.onlineVideos ?
              <div className="trending-video" ref={(div) => { this.vds_tiktok = div; }}>
                <div className="trending-video-focus">
                 <h3>Tiktok Videos</h3>

                 <Slider {...settings}>

                   { this.state.onlineVideos.tiktok.map((item, index) =>
                      <div key={index} className={(this.state.currentSliderVideo.Id == item.Id && this.state.currentSlider == 'tiktok') ? 'item-slide selected': 'item-slide' } onClick={(e)=> {this.handleOpenPlayer(e,'tiktok', item)}}><VdsSlideItem onClick={()=> {this.handleOpenPlayer()}} key={index} detail={item} handlePlayPlayer={this.handlePlayPlayer.bind(this)} handleDownloadPlayer={this.handleDownloadPlayer.bind(this)} /></div>
                   ) }
                  </Slider>

                  { (this.state.showSlidePlayer && this.state.currentSlider == 'tiktok' ) ? <div className={ this.state.currentSliderPlayerActive ? "videoplay-sec vds-videoplay-active" : "videoplay-sec"}>
                      <div className="sec-close" onClick={() => {this.handleClosePlayer()}} >
                          <IoIosClose />
                        </div>

                        <div className="playvideoImg">
                            <img src={this.state.currentSliderVideo.VideoThumbnail != "#" ? this.state.currentSliderVideo.VideoThumbnail : process.env.PUBLIC_URL + '/placeholder.jpg'} />
                        </div>
                        <div className={this.state.currentSliderVideoPlayerSource ? "item-slide-text vds-player-active" : "item-slide-text"}>
                          {this.state.currentSliderVideoPlayerSource?
                              <div className="vds-video-player-wrap"><ReactPlayer url={this.state.currentSliderVideoPlayerSource} playing controls width="100%" /></div>:
                              <div className="vds-video-text-wrap">
                              {this.state.currentSliderVideoPlayerLoader ?
                                <VdsLoader/> :
                                <div>
                                    <Button color="secondary" onClick={() => {this.onPlayClick(this.state.currentSliderVideo.Id)}} ><IoMdPlay /> Play</Button>
                                    <Button color="secondary" onClick={() => {this.onDownloadClick(this.state.currentSliderVideo.Id)}} ><IoIosDownload /> Download</Button>
                                     <h3>{this.state.currentSliderVideo.VideoTitle}</h3>
                                     <p>{this.state.currentSliderVideo.VideoDescription}</p>
                                </div>
                              }
                              </div>
                          }
                        </div>
                  </div> : ''}

                </div>
              </div> : ''}

          </div>
      </div>
    );
}
}

export default Videos;
